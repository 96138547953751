#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  .site-layout-background {
    display: flex;
    flex-direction: row;
    justify-content: right;  
  }
  .site-layout .site-layout-background {
    background: #fff;
    
  }
  .header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 56px;
    background-color: white;
    align-items: center;
    box-sizing: border-box;
    padding-right: 16px;
    -webkit-app-region: drag;
    -webkit-user-select: none;
  
  }
  .header_avatar{
    -webkit-app-region: no-drag;
    cursor: pointer;
  }
  .header_min{
    cursor: pointer;
    margin-right: 8px;
    -webkit-app-region: no-drag;
  }
  .header_max{
    margin-right: 8px;
    cursor: pointer;
    -webkit-app-region: no-drag;
  }
  .header_close{
    cursor: pointer;
    -webkit-app-region: no-drag;
  }