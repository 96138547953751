



.ant-input {
    &::placeholder {
      color: #999999 !important;
    }
  }
  .ant-select-selection-placeholder {
    color: #999999;
  }
.h_round_bg{
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    box-shadow:1px 1px 4px #dddddd;
    height: 100px;
}
.h_round_bg_center{
    justify-content: center;
    align-items: center;
}

.v_round_bg{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow:1px 1px 4px #dddddd;
    height: 100px;
}
.v_round_bg_center{
    justify-content: center;
    align-items: center;
}
.h_layout{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.h_center{
    justify-content: center;
}
.h_v_center{
    align-content: center;
}
.padding_all_normal{
    padding: 15px;
}
.padding_left_normal{
    padding-left: 15px;
}
.padding_right_normal{
    padding-left: 15px;
}
.padding_top_xxl{
    padding-top: 35px;

}
.padding_bottom_xxl{
    padding-bottom: 35px;

}
.margin_left_normal{
    margin-left: 15px;
}
.margin_top_normal{
    margin-top: 15px;
}
.margin_bottom_normal{
    margin-bottom: 15px;
}
.margin_bottom_small{
    margin-bottom: 5px;
}
.margin_top_xl{
    margin-top: 25px;
}
.margin_top_xxl{
    margin-top: 35px;
}
.v_layout{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.just_between{
    justify-content: space-between;
    align-content: center;
}

.small_text{
    font-size: 14px;
}
.normal_text{
    font-size: 18px;
}
.largest_text{
    font-size: 22px;
}
.small_margin_left{
    margin-left: 4px;
}
.normal_margin_left{
    margin-left: 8px;
}
.small_margin_right{
    margin-right: 4px;
}
.normal_margin_right{
    margin-right: 8px;
}

.small_margin_top{
    margin-top: 4px;
}
.normal_margin_top{
    margin-top: 8px;
}
.small_margin_bottom{
    margin-bottom: 4px;
}
.normal_margin_bottom{
    margin-bottom: 8px;
}